import { memo, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { setBalance } from 'src/app/store/slices/balance/slice';
import { selectBalances } from 'src/app/store/slices/balance/selectors';
import { DEFAULT_BALANCE, HIDDEN_BALANCES } from 'src/shared/constants/constants';
import { store, useAppDispatch } from 'src/app/store/store';
import { selectSelectedSubAccounts, selectSubAccounts } from 'src/app/store/slices/sub-accounts/selectors';

import Button from 'src/shared/ui/button/button/button';
import useAlert from 'src/shared/libs/hooks/use-alert';
import Delimiter from 'src/shared/ui/delimiter/delimiter/delimiter';

import { ReactComponent as DiscordIconSvg } from 'src/shared/assets/images/socials/discord.svg';
import { ReactComponent as TelegramIconSvg } from 'src/shared/assets/images/socials/telegram.svg';
import { ReactComponent as LogotypeIconSvg } from 'src/shared/assets/images/header/logotype.svg';

import { handleSupportTelegram, join } from 'src/shared/libs/helpers/helper.lib';
import { EStatus } from 'src/shared/types/global-types';
import { PositionCloseDetail } from 'src/pages/trading/model/types';
import { fetchOrderCancelAll, fetchPositionsCloseAll } from 'src/pages/trading/model/thunks';
import { selectHiddenBalance } from 'src/pages/settings/model/selectors';
import {
  selectStatusCloseOrders, selectStatusClosePositions, selectPositionsPnl,
} from 'src/pages/trading/model/selectors';

import AsideMenu from './components/aside-menu/aside-menu';
import AccountBlock from './components/account-block/account-block';
import AccountSelect from './components/account-select/account-select';

import './aside.scss';

const Aside = memo(() => {
  const dispatch = useAppDispatch();
  const { setAlertMessage } = useAlert();
  const pnl = useSelector(selectPositionsPnl);
  const balanceVisible = useSelector(selectHiddenBalance);
  const subAccounts = useSelector(selectSubAccounts) || [];
  const statusCloseOrders = useSelector(selectStatusCloseOrders);
  const selectedSubAccount = useSelector(selectSelectedSubAccounts);
  const statusClosePositions = useSelector(selectStatusClosePositions);
  const { balances, selectedBalance } = useSelector(selectBalances);

  const handleSetBalance = useCallback((subAccountId: number) => {
    const interval = setInterval(() => {
      const { balances } = store.getState().balances;

      if (balances?.sub_accounts?.length) {
        const currentBalance = balances.sub_accounts.find(
          (account) => account.sub_account_id === subAccountId,
        ) || DEFAULT_BALANCE;

        dispatch(setBalance(currentBalance));
        clearInterval(interval);
      }
    }, 100);

    return () => clearInterval(interval);
  }, [dispatch]);

  const closeAllPositions = async () => {
    const { payload } = await dispatch(fetchPositionsCloseAll());

    if (Array.isArray(payload) && payload.length === 0) {
      setAlertMessage('Позиции закрыты', 'success');
    } else if (Array.isArray(payload) && payload.length > 0) {
      payload.forEach((detail: PositionCloseDetail) => {
        const message = `asset: ${detail.symbol}, detail: ${detail.detail}`;
        setAlertMessage(message, 'error');
      });
    } else if (typeof payload === 'string') {
      setAlertMessage(payload, 'error');
    } else {
      setAlertMessage('Произошла внутренняя ошибка', 'error');
    }
  };

  const closeAllOrders = async () => {
    const { payload } = await dispatch(fetchOrderCancelAll());

    if (Array.isArray(payload) && payload.length === 0) {
      setAlertMessage('Заявки отменены', 'success');
    } else if (Array.isArray(payload) && payload.length > 0) {
      payload.forEach((detail: PositionCloseDetail) => {
        const message = `asset: ${detail.symbol}, detail: ${detail.detail}`;
        setAlertMessage(message, 'error');
      });
    } else if (typeof payload === 'string') {
      setAlertMessage(payload, 'error');
    } else {
      setAlertMessage('Произошла внутренняя ошибка', 'error');
    }
  };

  useEffect(() => {
    if (balances?.sub_accounts?.length && selectedBalance) {
      const currentBalance = balances.sub_accounts.find((account) => account.sub_account_id === selectedBalance.sub_account_id) || DEFAULT_BALANCE;
      dispatch(setBalance(currentBalance));
    }
  }, [balances]);

  return (
    <aside>
      <div className="logotype">
        <Link to="/"><LogotypeIconSvg /></Link>
      </div>

      <div className="accounts">
        <div className="account-wrapper">
          <AccountSelect
            dispatch={dispatch}
            selectedSubAccount={selectedSubAccount}
            onChangeBalanceById={handleSetBalance}
            subAccounts={subAccounts}
          />

          <AccountBlock
            selectedSubAccount={selectedSubAccount}
            balance={selectedBalance}
          />
        </div>
      </div>

      <AsideMenu />
      <Delimiter />

      <div className="actions">
        <Button loading={statusClosePositions === EStatus.loading} disabled={statusClosePositions === EStatus.loading} onClick={closeAllPositions} background="green">Закрыть все позиции</Button>

        <div className="pnl">
          <p className="title">PNL активных сделок</p>
          <p className={join('value', parseFloat(pnl || '0') > 0 ? 'value_plus' : parseFloat(pnl || '0') < 0 ? 'value_minus' : '')}>
            <span>{!balanceVisible ? (pnl || '0.0') : HIDDEN_BALANCES}</span>
            <span>USDT</span>
          </p>
        </div>

        <Button loading={statusCloseOrders === EStatus.loading} disabled={statusCloseOrders === EStatus.loading} onClick={closeAllOrders} background="black">Отменить все заявки</Button>
      </div>

      <Delimiter />

      <div className="socials">
        <p>Узнавайте первыми<br /> об обновлениях проекта:</p>
        <ul>
          <li className="social-telegram"><button onClick={handleSupportTelegram}><TelegramIconSvg /></button></li>
          <li className="social-discord"><a href="https://discord.gg/TUP9BzTvfX" target="_blank"><DiscordIconSvg /></a></li>
        </ul>
      </div>
    </aside>
  );
});
export default Aside;
