import { useChangePageTitle } from 'src/shared/libs/hooks/use-change-page-title';
import Empty from 'src/entities/empty/empty';

import './diary.scss';

const pageTitle = 'Дневник';

const DiaryPage = () => {
  useChangePageTitle(pageTitle);

  return (
    <div className="diary-page">
      <Empty>Эта страница в процессе разработки ...</Empty>
    </div>
  );
};

export default DiaryPage;
