import { Navigate, Outlet } from 'react-router-dom';
import { MainLayout } from 'src/layouts';
import { useAuth } from 'src/shared/libs/hooks/use-auth';

export const PrivateRouter = () => {
  const { isAuth, isWelcomePage } = useAuth();

  if (isAuth) {
    if (isWelcomePage) {
      return <Navigate to="/welcome" />;
    }
    return (
      <MainLayout page="">
        <Outlet />
      </MainLayout>
    );
  }
  return <Navigate to="auth/login" />;
};
