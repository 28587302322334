import DatePicker from 'react-datepicker';
import moment from 'moment';
import ru from 'date-fns/locale/ru';

import { ReactComponent as CalendarIconSvg } from 'src/shared/assets/images/account/calendar.svg';

import 'react-datepicker/dist/react-datepicker.css';
import './data-range-picker.scss';
import { setUserDateRange, join } from 'src/shared/libs/helpers/helper.lib';
import { DateRange, DateRangeType, Nullable } from 'src/shared/types/global-types';
import { useState } from 'react';

const dateRangeList: DateRangeType[] = [
  {
    name: 'Сегодня',
    range: 'Today',
  },
  {
    name: 'Вчера',
    range: 'Yesterday',
  },
  {
    name: 'Неделя',
    range: 'Week',
  },
  {
    name: 'Месяц',
    range: 'Month',
  },
  {
    name: 'Год',
    range: 'Year',
  },
];

interface IDataPickerRangeProps {
  setDateRange: (value: any) => void
  dateRange: string[]
  setPage: (value: number) => void
  removeData: () => void
  monthsShown?: number
  whiteMode?: boolean
}
const DataPickerRange = ({
  setDateRange, dateRange, setPage, removeData, whiteMode, monthsShown,
}: IDataPickerRangeProps) => {
  const [startDate, endDate] = dateRange;
  const [selectedDateRange, setSelectedDateRange] = useState<Nullable<DateRange>>(null);

  const handleSetDateRage = (update: [Nullable<Date>, Nullable<Date>]) => {
    const [firstData, secondData] = update;

    if (firstData && secondData) {
      setPage(1);
      removeData();
    }

    setDateRange(update);
  };

  const handleSetUserDateRange = (dateRage: DateRange) => {
    if (dateRage === selectedDateRange) return;

    setSelectedDateRange(dateRage);
    setUserDateRange(dateRage, setDateRange, setPage, removeData);
  };

  return (
    <div className="data-picker-container">
      <ul>
        {
          dateRangeList.map((date) => (
            <li
              key={date.range}
              role="button"
              tabIndex={0}
              onClick={() => handleSetUserDateRange(date.range)}
              onKeyDown={() => {}}
            >{date.name}
            </li>
          ))
        }
      </ul>
      <div className={join('data-picker', whiteMode && 'white-mode')}>
        <CalendarIconSvg />
        <DatePicker
          selectsRange
          startDate={startDate ? moment(startDate).toDate() : undefined}
          endDate={endDate ? moment(endDate).toDate() : undefined}
          monthsShown={monthsShown || 2}
          showPreviousMonths
          dateFormat="dd/MM/yyyy"
          locale={ru}
          isClearable
          onChange={(update) => handleSetDateRage(update)}
        />
      </div>
    </div>
  );
};

export default DataPickerRange;
