import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EStatus, Nullable } from 'src/shared/types/global-types';
import { Asset } from 'src/app/store/slices/assets/types';
import { SubAccountAsset } from '../../../app/store/slices/sub-accounts/types';
import { AccountBalance, ITransfersSlice } from './types';
import { fetchTransfers } from './thunks';

const initialState: ITransfersSlice = {
  currentBalanceFrom: null,
  currentBalanceTo: null,
  accountBalances: [],
  transferAsset: null,
  currentAsset: null,
  assetPrecision: 1,
  status: EStatus.success,
};

export const transfersSlice = createSlice({
  name: 'transfers',
  initialState,
  reducers: {
    setCurrentBalanceFrom: (state, action: PayloadAction<Nullable<AccountBalance>>) => {
      state.currentBalanceFrom = action.payload;
    },
    setCurrentBalanceTo: (state, action: PayloadAction<Nullable<AccountBalance>>) => {
      state.currentBalanceTo = action.payload;
    },
    setSubAccountBalances: (state, action: PayloadAction<AccountBalance[]>) => {
      state.accountBalances = action.payload;
    },
    setTransfersAsset: (state, action: PayloadAction<Nullable<SubAccountAsset>>) => {
      state.transferAsset = action.payload;
    },
    removeTransfersAsset: (state) => {
      state.transferAsset = null;
    },
    setCurrentAsset: (state, action: PayloadAction<Nullable<Asset>>) => {
      state.currentAsset = action.payload;
    },
    setAssetPrecision: (state, action: PayloadAction<number>) => {
      state.assetPrecision = action.payload;
    },
    removeTransfersSlice: (state) => {
      state.currentBalanceFrom = null;
      state.currentBalanceTo = null;
      state.accountBalances = [];

      state.currentAsset = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTransfers.pending, (state) => {
        state.status = EStatus.loading;
      })
      .addCase(fetchTransfers.fulfilled, (state) => {
        state.status = EStatus.success;
      })
      .addCase(fetchTransfers.rejected, (state) => {
        state.status = EStatus.rejected;
      });
  },
});

export const {
  setAssetPrecision, setCurrentBalanceFrom, setCurrentBalanceTo, setSubAccountBalances, removeTransfersSlice, setTransfersAsset, setCurrentAsset, removeTransfersAsset,
} = transfersSlice.actions;
export const transfersReducer = transfersSlice.reducer;
