import { useDispatch } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { authReducer } from 'src/pages/auth/login/model/slice';
import { leveragesReducer } from 'src/pages/leverages/model/slice';
import { registrationReducer } from 'src/pages/auth/registration/model/slice';
import { tradingReducer } from 'src/pages/trading/model/slice';
import { transfersReducer } from 'src/widgets/transfers/model/slice';
import { transfersHistoryReducer } from 'src/widgets/transfer-history/model/slice';
import { forgotPasswordReducer } from 'src/pages/auth/forgot-password/model/slice';
import { accountReducer } from 'src/pages/account/model/slice';
import { notificationsReducer } from 'src/widgets/notifications/model/slice';
import { tradeSettingsReducer } from 'src/pages/settings/model/slice';
import { alertReducer } from './slices/alert/slice';
import { exchangesReducer } from './slices/exchanges/slice';
import { passwordStepsReducer } from './slices/password-steps/slice';
import { subAccountsReducer } from './slices/sub-accounts/slice';
import { balanceReducer } from './slices/balance/slice';
import { assetsReducer } from './slices/assets/slice';
import { verificationsReducer } from './slices/verifications/slice';
import { userReducer } from './slices/user/slice';
import { kycReducer } from './slices/kyc/slice';
import { walletsReducer } from './slices/wallets/slice';
import { subscriptionsReducer } from './slices/subscriptions/slice';
import { rebateReducer } from './slices/bonus/slice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    registration: registrationReducer,
    forgotPassword: forgotPasswordReducer,
    alert: alertReducer,
    passwordSteps: passwordStepsReducer,
    leverages: leveragesReducer,
    exchanges: exchangesReducer,
    subAccounts: subAccountsReducer,
    trading: tradingReducer,
    balances: balanceReducer,
    assets: assetsReducer,
    transfers: transfersReducer,
    transfersHistory: transfersHistoryReducer,
    user: userReducer,
    verifications: verificationsReducer,
    kyc: kycReducer,
    wallets: walletsReducer,
    account: accountReducer,
    subscriptions: subscriptionsReducer,
    rebate: rebateReducer,
    notifications: notificationsReducer,
    tradeSettings: tradeSettingsReducer,
  },
});

type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
