import qs from 'qs';
import {
  memo, useCallback, useEffect, useLayoutEffect, useState,
} from 'react';
import { useSelector, batch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from 'src/app/store/store';
import { selectExchanges } from 'src/app/store/slices/exchanges/selectors';
import { selectSubAccounts } from 'src/app/store/slices/sub-accounts/selectors';
import {
  SearchAssetsParamsType,
  fetchNetworks,
  fetchSearchAssets,
  fetchWalletAddress, fetchWalletAddressParams,
  AssetsParams, fetchAssets,
  NetworksParams,
} from 'src/app/store/slices/assets/thunks';
import { selectKycVerify } from 'src/app/store/slices/user/selectors';
import {
  selectAssets, selectAssetsSlice, selectNetworks, selectSearchAssets,
} from 'src/app/store/slices/assets/selectors';
import { Asset, Networks, SearchAssetType } from 'src/app/store/slices/assets/types';
import {
  removeSearchAssets,
  setCurrentAssetWallet, setCurrentNetwork,
  removeAssetsSlice,
  removeWalletAddress,
  removeAssets,
  removeNetworks,
} from 'src/app/store/slices/assets/slice';
import { SubAccountType } from 'src/app/store/slices/sub-accounts/types';

import Select from 'src/shared/ui/select/select';
import CopyIcon from 'src/shared/assets/icons/copy-icon/copy-icon';
import Passport from 'src/entities/passport/password';
import InputText from 'src/shared/ui/input/input-text/input-text';
import InputForm from 'src/shared/ui/input/input-form/input-form';
import SelectSkeleton from 'src/shared/ui/skeleton/select-skeleton/select-skeleton';
import AdviceParagraph from 'src/shared/ui/paragraph/advice-paragraph/advice-paragraph';
import QRCodeGenerator from 'src/entities/qr-code/qr-code';
import DefaultIconSvg from 'src/shared/assets/images/exchange/binance.svg';

import { EStatus, Nullable } from 'src/shared/types/global-types';
import { BalanceType } from 'src/entities/balance-type';
import { useIntersectionObserver } from 'src/shared/libs/hooks/use-observer';
import {
  debounce, getSubAccountImage, join, sortSubAccountsByFavorite,
  typeSubAccountBack,
} from 'src/shared/libs/helpers/helper.lib';

import SelectCurrency from '../select-currency/select-currency';
import '../../transfers.scss';

const Replenishment = memo(() => {
  const dispatch = useAppDispatch();

  const assets = useSelector(selectAssets);
  const networks = useSelector(selectNetworks);
  const verifyKyc = useSelector(selectKycVerify);
  const subAccounts = useSelector(selectSubAccounts);
  const searchAssets = useSelector(selectSearchAssets);
  const { exchanges } = useSelector(selectExchanges);
  const {
    walletAddress,
    statusWalletAddress,
    currentNetwork,
    currentAsset,
  } = useSelector(selectAssetsSlice);

  const [showKYC, setShowKYC] = useState(true);

  const [fetchAssetsPage, setFetchAssetsPage] = useState(1);
  const [fetchAssetsLimit, setFetchAssetsLimit] = useState(20);
  const [fetchAssetsType, setFetchAssetsType] = useState('SPOT');

  useIntersectionObserver(fetchAssetsPage, assets?.meta?.total_pages || 1, assets?.items, 'instrument-observer', setFetchAssetsPage);

  const [subAccoundId, setSubAccoundId] = useState<Nullable<number>>(null);
  const [currentSubAccount, setCurrentSubAccount] = useState<Nullable<SubAccountType>>(null);
  const [searchAssetSymbol, setSearchAssetSymbol] = useState('');

  const handleSetCurrentAsset = useCallback((asset: Asset | SearchAssetType) => {
    batch(() => {
      dispatch(removeWalletAddress());
      dispatch(setCurrentAssetWallet(asset));
    });
  }, [dispatch]);

  const handleSetCurrentNetwork = (network: Networks) => {
    dispatch(setCurrentNetwork(network));
  };

  const handleSetSubAccount = (subAccount: SubAccountType) => {
    if (currentSubAccount?.id === subAccount.id) return;

    batch(() => {
      dispatch(removeAssets());
      dispatch(removeNetworks());
      dispatch(removeWalletAddress());
    });
    setCurrentSubAccount(subAccount);
    setSubAccoundId(subAccount.id);
  };

  const debouncedFetch = useCallback(
    debounce((searchParams: SearchAssetsParamsType) => {
      dispatch(fetchSearchAssets(searchParams));
    }, 500) as any,
    [dispatch],
  );

  const fetchSearchAsset = async (assetId: number, assetType: string, exchangeId: number) => {
    try {
      const searchAssetsParams: SearchAssetsParamsType = {
        asset_ids: [assetId],
        asset_trading_type: assetType,
        exchange_id: exchangeId,
        is_active_to_deposit: true,
      };

      const response = await dispatch(fetchSearchAssets(searchAssetsParams));
      const [searchAsset] = unwrapResult(response);

      if (searchAsset) handleSetCurrentAsset(searchAsset);
    } catch (error) {
      console.error('failed fetch search asset', error);
    }
  };

  const handleFetchAssets = async (params: AssetsParams, callback: (asset: Asset) => void) => {
    try {
      const response = await dispatch(fetchAssets(params));
      const payload = unwrapResult(response);
      const assets = payload.items;

      if (Array.isArray(assets) && assets.length) {
        callback(assets[0]);
      }
    } catch (error) {
      console.error('failed fetch assets', error);
    }
  };

  useEffect(() => {
    if (verifyKyc === undefined) return;

    if (verifyKyc) {
      setShowKYC(true);
    } else {
      setShowKYC(false);
    }
  }, [verifyKyc]);

  useEffect(() => {
    if (assets && currentAsset) {
      dispatch(setCurrentAssetWallet(currentAsset));
    }
  }, [assets, currentAsset]);

  useEffect(() => {
    if (searchAssetSymbol.length > 1 && currentSubAccount) {
      const searchAssetsParams: SearchAssetsParamsType = {
        query: searchAssetSymbol.toUpperCase(),
        asset_trading_type: 'SPOT',
        exchange_id: currentSubAccount.exchange_id,
        is_active_to_deposit: true,
      };

      debouncedFetch.execute(searchAssetsParams);
    }

    if (searchAssetSymbol.length === 0) {
      dispatch(removeSearchAssets());
      debouncedFetch.stop();
    }
  }, [searchAssetSymbol, currentSubAccount]);

  useEffect(() => {
    if (verifyKyc) {
      if (currentAsset && currentNetwork && subAccoundId) {
        const params: fetchWalletAddressParams = {
          assetId: currentAsset.id,
          networkId: currentNetwork.network.id,
          subAccountId: subAccoundId,
        };

        dispatch(fetchWalletAddress(params));
      }
    }
  }, [currentNetwork, subAccoundId, verifyKyc]);

  useEffect(() => {
    if (currentAsset && verifyKyc) {
      const params: NetworksParams = {
        asset_id: currentAsset.id,
        is_active_to_deposit: true,
      };
      dispatch(fetchNetworks(params));
    }
  }, [currentAsset, verifyKyc]);

  useEffect(() => {
    if (subAccounts) {
      const [firstElement] = subAccounts.slice().sort(sortSubAccountsByFavorite);
      setCurrentSubAccount(firstElement);
      setSubAccoundId(firstElement.id);
    }
  }, [subAccounts]);

  useEffect(() => {
    if (verifyKyc && currentSubAccount) {
      const params: AssetsParams = {
        asset_trading_type: fetchAssetsType,
        limit: fetchAssetsLimit,
        page: fetchAssetsPage,
        exchange_id: currentSubAccount.exchange_id,
        is_active_to_deposit: true,
      };

      handleFetchAssets(params, handleSetCurrentAsset);
    }
  }, [fetchAssetsPage, verifyKyc, currentSubAccount]);

  useEffect(() => () => {
    dispatch(removeAssetsSlice());
  }, []);

  useEffect(() => {
    const queryString = window.location.search;
    const parsedParams = qs.parse(queryString, { ignoreQueryPrefix: true });

    const replenishmentSubAccountId = parsedParams?.replenishment_sub_account_id;
    const exchangeId = parsedParams?.exchange_id as string;
    const assetId = parsedParams?.asset_id as string;
    const assetType = parsedParams?.asset_type as string;

    if (replenishmentSubAccountId && exchangeId && assetId && assetType) {
      const currentSubAccount = subAccounts?.find((subAccount) => subAccount.id === Number(replenishmentSubAccountId));

      if (currentSubAccount) {
        setSubAccoundId(currentSubAccount.id);
        setCurrentSubAccount(currentSubAccount);

        fetchSearchAsset(Number(assetId), assetType, Number(exchangeId));
      }
    }

    if (replenishmentSubAccountId) {
      const findSubAccount = subAccounts?.find((subAccount) => subAccount.id === Number(replenishmentSubAccountId));
      if (findSubAccount) handleSetSubAccount(findSubAccount);
    }

    if (subAccounts) {
      const currentSubAccount = subAccounts.find((subAccount) => subAccount.id === Number(replenishmentSubAccountId));

      if (currentSubAccount) {
        setCurrentSubAccount(currentSubAccount);
      }
    }
  }, []);

  return (
    <div className="replenishment-wrapper">

      <div className={join('replenishment', !showKYC && 'not_active')}>
        <h4 className="title">Пополнение</h4>

        <div className="instruments">
          <InputForm title="Валюта">
            { assets && assets.items.length > 0 ? (
              <Select
                isActiveSearch
                searchValue={searchAssetSymbol}
                isSearch={setSearchAssetSymbol}
                isSelectValue={(
                  <SelectCurrency className="instrument-observer" asset={currentAsset} handleSetAsset={() => {}} />
                )}
              >
                {
                  searchAssets ? searchAssets.map((item) => (
                    <SelectCurrency className="instrument-observer" key={item.id} asset={item} handleSetAsset={handleSetCurrentAsset} />
                  ))
                    : assets.items.map((asset) => (
                      <SelectCurrency className="instrument-observer" key={asset.id} asset={asset} handleSetAsset={handleSetCurrentAsset} />
                    ))
                }

              </Select>
            ) : <SelectSkeleton /> }
          </InputForm>

          <InputForm title="Сеть">
            {networks && currentNetwork && networks?.length > 0 ? (
              <Select
                isSelectValue={(
                  <div className="network">
                    <span className="short-name">{currentNetwork.network.symbol}</span>
                    <span className="full-name">{currentNetwork.network.name}</span>
                  </div>
                )}
                isActiveSearch={false}
              >
                {
                  networks?.map((network) => (
                    <div
                      key={network.network.id}
                      className="network"
                      role="button"
                      tabIndex={0}
                      onKeyDown={() => handleSetCurrentNetwork(network)}
                      onClick={() => handleSetCurrentNetwork(network)}
                    >
                      <span className="short-name">{network.network.symbol}</span>
                      <span className="full-name">{network.network.name}</span>
                    </div>
                  ))
                }
              </Select>
            ) : <SelectSkeleton /> }
          </InputForm>
        </div>

        <InputForm title="На счет:">
          {subAccounts && currentSubAccount ? (
            <Select
              isActiveSearch={false}
              isSelectValue={(
                <div className="sub-account-select-item">
                  <img src={exchanges ? getSubAccountImage(currentSubAccount.exchange_id, exchanges) : DefaultIconSvg} alt="exchange" />
                  <BalanceType type={typeSubAccountBack(currentSubAccount.exchange_id)} />
                  {currentSubAccount.user_name}
                </div>
              )}
            >
              {subAccounts.slice().sort(sortSubAccountsByFavorite).map((subAccount) => (
                <div
                  key={subAccount.id}
                  className="sub-account-select-item"
                  role="button"
                  tabIndex={0}
                  onKeyDown={() => handleSetSubAccount(subAccount)}
                  onClick={() => handleSetSubAccount(subAccount)}
                >
                  <img src={exchanges ? getSubAccountImage(subAccount.exchange_id, exchanges) : DefaultIconSvg} alt="exchange" />
                  <BalanceType type={typeSubAccountBack(subAccount.exchange_id)} />
                  {subAccount.user_name}
                </div>
              ))}
            </Select>
          ) : <SelectSkeleton />}
        </InputForm>

        <div className="account-address">
          <InputForm title="Адрес кошелька">
            {statusWalletAddress === EStatus.success && assets && networks ? (
              <InputText
                value={walletAddress?.address || 'Нет адреса'}
                isDisabled
                onChange={() => {}}
              >
                <CopyIcon value={walletAddress?.address} />

              </InputText>
            ) : <SelectSkeleton height={43} />}
          </InputForm>
        </div>

        <div style={{ display: walletAddress?.tag ? 'flex' : 'none' }} className="account-address">
          <InputForm title="Memo">
            <InputText
              value={walletAddress?.tag || 'Нет адреса'}
              isDisabled
              onChange={() => {}}
            >
              <CopyIcon value={walletAddress?.tag} />

            </InputText>
          </InputForm>
        </div>

        <div className="qr-code">
          {verifyKyc && (
            <>
              <p>Отсканируйте этот QR-code с помощью приложения
                <br /> кошелька на вашем мобильном телефоне<br />
                или скопируйте в буфер обмена
              </p>
              <QRCodeGenerator qrText={walletAddress?.address} />
            </>
          )}
        </div>

        <AdviceParagraph big>Отправляйте только {currentAsset?.symbol} на этот адрес. Если отправите
          токен или монету в другой валюте, то потеряете деньги.
        </AdviceParagraph>
      </div>

      { !showKYC && (
        <div className="replenishment-passport">
          <Passport visible={showKYC} />
        </div>
      )}
    </div>
  );
});

export default Replenishment;
