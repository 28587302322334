import { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectTheme } from 'src/app/store/slices/user/selectors';
import { themeMode } from 'src/app/store/slices/user/slice';
import { EThemeMode } from 'src/app/store/slices/user/types';
import { useAppDispatch } from 'src/app/store/store';

export const useTheme = () => {
  const dispatch = useAppDispatch();
  const theme = useSelector(selectTheme);

  const setTheme = (mode: EThemeMode) => dispatch(themeMode(mode));

  useLayoutEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);

    localStorage.setItem('appTheme', theme);
  }, [theme]);

  return {
    theme, setTheme,
  };
};
