import { EStatus, Nullable, TradingType } from 'src/shared/types/global-types';

export enum EKycStatus {
  NOT_VERIFIED = 'NOT_VERIFIED',
  CREATED = 'CREATED',
  VERIFIED = 'VERIFIED',
  IN_PROCESS = 'IN_PROCESS',
  IN_REVIEW = 'IN_REVIEW'
}

export type User = {
  public_id: string,
  email: string,
  phone_number: string,
  language: string,
  discord_name: string,
  telegram_name: Nullable<string>,
  full_name: string
  is_verified: boolean,
  is_banned: boolean,
  can_withdraw: boolean,
}

export type UserSettings = {
  is_active_ga: boolean
  is_active_yubikey: boolean
  login_verifications: string[]
  type_sms_code: string;
}

export type ConnectedGALink = {
  secret_key: string
  uri: string
}

export type IpAddress = {
  id: number,
  ip: string,
  user_name: string
}

export enum EThemeMode {
  LIGHT = 'light',
  DARK = 'dark'
}

export type Rebates = {
  exchange_id: number,
  rebate_percent_by_type: {
    rebate_percent: string
    maker_commission: string
    taker_commission: string
    trading_type: TradingType
  }[]
}

export interface IUserSlice {
  user: Nullable<User>
  settings: Nullable<UserSettings>
  rebates: {
    settings: Nullable<Rebates[]>
  }
  kycStatus: EKycStatus
  theme: EThemeMode
  currentTogglerTwoFA: string
  connectedGALink: Nullable<ConnectedGALink>
  IpAddresses: Nullable<IpAddress[]>
  statusAddIpAdress: EStatus
  statusDeleteIpAdress: EStatus
  status: EStatus
  statusUserSettings: EStatus
  statusPhone: EStatus
  statusEmail: EStatus
  statusDiscord: EStatus,
  statusTelegram: EStatus,
  statusLoginVerify: EStatus
  statusGA: EStatus
  statusYubikey: EStatus
  statusSmsType: EStatus
  statusChangePassword: EStatus
}

export type ResponseUserKycStatus = {
  status: EKycStatus
}
