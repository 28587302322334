import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { EStatus, Nullable } from 'src/shared/types/global-types';
import { sortNetworks, sortNetworksAssets, sortNetworksUsdt } from 'src/shared/libs/helpers/helper.lib';
import {
  fetchAllNetworks,
  fetchAllNetworksSearch,
  fetchAssets, fetchAssetsFutures, fetchNetworks, fetchNetworksAssets, fetchSearchAssets, fetchWalletAddress, fetchWalletAttributes, fetchWithdrawals,
} from './thunks';
import {
  IAssetsSlice, Networks, Asset, SearchAssetType,
  Network,
  Assets,
} from './types';

const initialState: IAssetsSlice = {
  instruments: [],
  networks: null,
  networksAll: null,
  walletAttributes: null,
  assets: null,
  networksAssets: null,
  currentAsset: null,
  assetsFutures: null,
  searchAssets: null,
  currentNetwork: null,
  currentNetworkAll: null,
  currentNetworkOutput: null,
  walletAddress: null,
  currentAssetOutput: null,
  status: EStatus.success,
  statusWalletAddress: EStatus.success,
  statusWithdrawal: EStatus.success,
};

export const assetsSlice = createSlice({
  name: 'assets',
  initialState,
  reducers: {
    setNetworks: (state, action: PayloadAction<Networks[]>) => {
      state.networks = action.payload;
    },
    setCurrentNetwork: (state, action: PayloadAction<Networks>) => {
      state.currentNetwork = action.payload;
    },
    setCurrentNetworkAll: (state, action: PayloadAction<Network>) => {
      state.currentNetworkAll = action.payload;
    },
    setCurrentAssetWallet: (state, action: PayloadAction<Asset | SearchAssetType>) => {
      state.currentAsset = action.payload;
    },
    setCurrentAssetOutput: (state, action: PayloadAction<Nullable<Assets>>) => {
      state.currentAssetOutput = action.payload;
    },
    setCurrentNetworkOutput: (state, action: PayloadAction<Nullable<Network>>) => {
      state.currentNetworkOutput = action.payload;
    },
    removeWalletAddress: (state) => {
      state.walletAddress = null;
    },
    removeAssetsSlice: (state) => {
      state.instruments = [];
      state.networks = null;
      state.networksAll = null;
      state.walletAttributes = null;
      state.assets = null;
      state.networksAssets = null;
      state.currentAsset = null;
      state.assetsFutures = null;
      state.searchAssets = null;
      state.currentNetwork = null;
      state.currentNetworkAll = null;
      state.currentNetworkOutput = null;
      state.currentAssetOutput = null;
      state.walletAddress = null;
    },
    removeAssets: (state) => {
      state.assets = null;
      state.currentAsset = null;
    },
    removeNetworks: (state) => {
      state.networks = null;
      state.currentNetwork = null;
    },
    removeSearchAssets: (state) => {
      state.searchAssets = null;
    },
    removeAllNetworks: (state) => {
      state.networksAll = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNetworks.pending, (state) => {
        state.status = EStatus.loading;
        state.networks = null;
      })
      .addCase(fetchNetworks.fulfilled, (state, action) => {
        state.status = EStatus.success;
        const { currentAsset } = state;

        if (currentAsset?.symbol === 'USDT') {
          state.networks = action.payload.slice().sort(sortNetworksUsdt);
          const [firstElement] = action.payload.slice().sort(sortNetworksUsdt);

          state.currentNetwork = firstElement;
        } else {
          state.networks = action.payload.slice().sort(sortNetworks);
          const [firstElement] = action.payload.slice().sort(sortNetworks);

          state.currentNetwork = firstElement;
        }
      })
      .addCase(fetchNetworks.rejected, (state) => {
        state.status = EStatus.rejected;
        state.networks = null;
      })

      .addCase(fetchAllNetworks.pending, (state) => {
        state.status = EStatus.loading;
      })
      .addCase(fetchAllNetworks.fulfilled, (state, action) => {
        state.status = EStatus.success;

        const [firstNetwork] = action.payload.items;

        if (state.networksAll?.items) {
          const allItems = [...state.networksAll.items, ...action.payload.items];
          const uniqueData = allItems.filter((item, index, self) => index === self.findIndex((t) => t.symbol === item.symbol));
          state.networksAll.items = uniqueData;
        } else {
          state.networksAll = action.payload;
          if (firstNetwork) {
            state.currentNetworkAll = firstNetwork;
          }
        }
      })
      .addCase(fetchAllNetworks.rejected, (state) => {
        state.status = EStatus.rejected;
        state.networksAll = null;
      })

      .addCase(fetchAllNetworksSearch.fulfilled, (state, action) => {
        state.status = EStatus.success;

        if (!action.payload.length) return;

        state.networksAll = {
          items: action.payload,
          meta: {
            total_items: action.payload.length,
            total_pages: 1,
          },
        };
      })

      .addCase(fetchWalletAttributes.pending, (state) => {
        state.walletAttributes = null;
      })
      .addCase(fetchWalletAttributes.fulfilled, (state, action) => {
        const [asset] = action.payload.assets;
        state.walletAttributes = action.payload;
        state.currentNetworkOutput = action.payload.network;
        state.currentAssetOutput = asset;
      })
      .addCase(fetchWalletAttributes.rejected, (state) => {
        state.walletAttributes = null;
      })

      .addCase(fetchWalletAddress.pending, (state) => {
        state.statusWalletAddress = EStatus.loading;
        state.walletAddress = null;
      })
      .addCase(fetchWalletAddress.fulfilled, (state, action) => {
        state.statusWalletAddress = EStatus.success;
        state.walletAddress = action.payload;
      })
      .addCase(fetchWalletAddress.rejected, (state) => {
        state.statusWalletAddress = EStatus.rejected;
        state.walletAddress = null;
      })

      .addCase(fetchWithdrawals.pending, (state) => {
        state.statusWithdrawal = EStatus.loading;
      })
      .addCase(fetchWithdrawals.fulfilled, (state, action) => {
        state.statusWithdrawal = EStatus.success;
      })
      .addCase(fetchWithdrawals.rejected, (state) => {
        state.statusWithdrawal = EStatus.rejected;
      })

      .addCase(fetchAssets.pending, (state) => {
        // state.assets = null;
      })
      .addCase(fetchAssets.fulfilled, (state, action) => {
        if (!state.assets) {
          state.assets = action.payload;
        } else {
          state.assets.items = state.assets.items.concat(action.payload.items);
        }
      })
      .addCase(fetchAssets.rejected, (state) => {
        state.assets = null;
      })

      .addCase(fetchSearchAssets.pending, (state) => {
        state.searchAssets = null;
      })
      .addCase(fetchSearchAssets.fulfilled, (state, action) => {
        const searchAssets = action.payload;

        // The server returns all asset types, both futures and spots. Assets can duplicate, we filter and keep only unique assets
        const uniqueAssets = searchAssets.reduce((acc, current) => {
          const x = acc.find((item) => item.symbol === current.symbol);
          if (!x) {
            acc.push(current);
          }
          return acc;
        }, [] as Asset[]);

        state.searchAssets = uniqueAssets;
      })
      .addCase(fetchSearchAssets.rejected, (state) => {
        state.searchAssets = null;
      })

      .addCase(fetchAssetsFutures.pending, (state) => {
        state.searchAssets = null;
      })
      .addCase(fetchAssetsFutures.fulfilled, (state, action) => {
        state.assetsFutures = action.payload;
      })
      .addCase(fetchAssetsFutures.rejected, (state) => {
        state.searchAssets = null;
      })

      .addCase(fetchNetworksAssets.pending, (state) => {
        state.networksAssets = null;
      })
      .addCase(fetchNetworksAssets.fulfilled, (state, action) => {
        const networkAssets = action.payload.filter((assetData) => assetData.asset.is_active_to_withdrawal === true).sort(sortNetworksAssets);
        const uniqueData = networkAssets.filter((item, index, self) => index === self.findIndex((t) => t.asset.symbol === item.asset.symbol));
        state.networksAssets = uniqueData.length ? uniqueData : null;
      })
      .addCase(fetchNetworksAssets.rejected, (state) => {
        state.networksAssets = null;
      });
  },
});

export const {
  setNetworks,
  setCurrentNetwork,
  setCurrentNetworkOutput,
  setCurrentAssetWallet,
  setCurrentAssetOutput,
  removeAssetsSlice,
  removeAssets,
  removeNetworks,
  removeSearchAssets,
  removeWalletAddress,
  setCurrentNetworkAll,
  removeAllNetworks,
} = assetsSlice.actions;

export const assetsReducer = assetsSlice.reducer;
