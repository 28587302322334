import classNames from 'classnames';
import { ReactNode } from 'react';
import { ReactComponent as HamsterIconSvg } from 'src/shared/assets/images/trading/wait-positions.svg';
import { ReactComponent as HamsterErrorIconSvg } from 'src/shared/assets/images/hamster-error.svg';
import { ReactComponent as HamsterErrorHoverIconSvg } from 'src/shared/assets/images/hamster-error-hover.svg';
import styles from './empty.module.scss';

interface IEmptyProps {
  children: ReactNode
  error?: boolean
  horizontal?: boolean
  className?: string
}
const Empty = ({
  children, error, horizontal, className,
}: IEmptyProps) => (
  <div className={classNames(
    styles.empty,
    className,
    { [styles.error]: error },
    { [styles.horizontal]: horizontal },
  )}
  >
    <p>
      {children}
    </p>
    {error ? (
      <div className={styles.hamsterWrapper}>
        <div className={styles.see}><HamsterErrorIconSvg /></div>
        <div className={styles.notSee}><HamsterErrorHoverIconSvg /></div>
      </div>
    ) : <HamsterIconSvg />}
  </div>
);

export default Empty;
