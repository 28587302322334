import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { EStatus } from 'src/shared/types/global-types';
import { EKycStatus, EThemeMode, IUserSlice } from './types';
import {
  fetchChangeConnectedGA,
  fetchChangePassword,
  fetchChangeSmsType,
  fetchChangeUserDiscord,
  fetchChangeUserPhoneNumber, fetchChangeUserTelegram, fetchLoginVerifications, fetchUser, fetchUserAddIpAddresses, fetchUserDeleteIpAddresses, fetchUserIpAddresses, fetchUserKycStatus, fetchUserRebatesSettings, fetchUserSettings,
} from './thunks';

const defaultTheme = localStorage.getItem('appTheme') as EThemeMode || EThemeMode.LIGHT;

const initialState: IUserSlice = {
  user: null,
  settings: null,
  theme: defaultTheme,
  rebates: {
    settings: null,
  },
  currentTogglerTwoFA: '',
  connectedGALink: null,
  IpAddresses: null,
  statusAddIpAdress: EStatus.success,
  kycStatus: EKycStatus.CREATED,
  statusDeleteIpAdress: EStatus.success,
  status: EStatus.success,
  statusUserSettings: EStatus.success,
  statusLoginVerify: EStatus.success,
  statusPhone: EStatus.success,
  statusEmail: EStatus.success,
  statusDiscord: EStatus.success,
  statusTelegram: EStatus.success,
  statusGA: EStatus.success,
  statusYubikey: EStatus.success,
  statusSmsType: EStatus.success,
  statusChangePassword: EStatus.success,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    addLoginVerificationType: (state, action: PayloadAction<string>) => {
      if (state.settings) {
        const index = state.settings.login_verifications.indexOf(action.payload);

        if (index !== -1) {
          // If the element exists, delete it from the array
          state.settings.login_verifications.splice(index, 1);
        } else {
          // If the element does not exist, add it to the array
          state.settings.login_verifications.push(action.payload);
        }
      }
    },
    activeGA: (state, action: PayloadAction<boolean>) => {
      if (state.settings) {
        state.settings.is_active_ga = action.payload;
        if (action.payload === true) {
          state.settings.login_verifications = state.settings.login_verifications.concat('ga');
        } else {
          state.settings.login_verifications = state.settings.login_verifications.filter((item) => item !== 'ga');
        }
      }
    },
    disabledPhoneVerification: (state) => {
      if (state.settings) {
        state.settings.login_verifications = state.settings.login_verifications.filter((item) => item !== 'sms' && item !== 'whatsapp');
      }
    },
    removeLoginVerificationTypes: (state) => {
      if (state.settings) {
        state.settings.login_verifications = [];
      }
    },
    removeConnectedGALink: (state) => {
      state.connectedGALink = null;
    },
    removeUserSlice: (state) => {
      state.user = null;
      state.settings = null;
      state.connectedGALink = null;
      state.IpAddresses = null;
      state.currentTogglerTwoFA = '';
    },
    setCurrentTogglerTwoFA: (state, action: PayloadAction<string>) => {
      state.currentTogglerTwoFA = action.payload;
    },
    setTypeSmsCode: (state, action: PayloadAction<string>) => {
      if (state.settings) {
        state.settings.type_sms_code = action.payload;
      }
    },
    removeIpAddress: (state, action: PayloadAction<number>) => {
      if (state.IpAddresses) {
        state.IpAddresses = state.IpAddresses.filter(((ipAddress) => ipAddress.id !== action.payload));
      }
    },
    cleareIpAddress: (state) => {
      state.IpAddresses = null;
    },
    setKycStatus: (state, action: PayloadAction<EKycStatus>) => {
      state.kycStatus = action.payload;
    },
    setKycVerify: (state, action: PayloadAction<boolean>) => {
      if (state.user) {
        state.user.is_verified = action.payload;
      }
    },
    setIpAddress: (state, action) => {
      state.IpAddresses = action.payload;
    },
    themeMode: (state, action: PayloadAction<EThemeMode>) => {
      state.theme = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.status = EStatus.loading;
        state.user = null;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.status = EStatus.success;
        state.user = action.payload;
      })
      .addCase(fetchUser.rejected, (state) => {
        state.status = EStatus.rejected;
        state.user = null;
      })

      .addCase(fetchUserSettings.pending, (state) => {
        state.statusUserSettings = EStatus.loading;
        state.user = null;
      })
      .addCase(fetchUserSettings.fulfilled, (state, action) => {
        state.statusUserSettings = EStatus.success;
        state.settings = action.payload;
      })
      .addCase(fetchUserSettings.rejected, (state) => {
        state.statusUserSettings = EStatus.rejected;
        state.user = null;
      })

      .addCase(fetchLoginVerifications.pending, (state) => {
        state.statusLoginVerify = EStatus.loading;
      })
      .addCase(fetchLoginVerifications.fulfilled, (state) => {
        state.statusLoginVerify = EStatus.success;
      })
      .addCase(fetchLoginVerifications.rejected, (state) => {
        state.statusLoginVerify = EStatus.rejected;
      })

      .addCase(fetchChangeUserPhoneNumber.pending, (state) => {
        state.statusPhone = EStatus.loading;
      })
      .addCase(fetchChangeUserPhoneNumber.fulfilled, (state, action) => {
        state.statusPhone = EStatus.success;

        if (state.user) {
          state.user.phone_number = action.payload;
        }
      })
      .addCase(fetchChangeUserPhoneNumber.rejected, (state) => {
        state.statusPhone = EStatus.rejected;
      })

      .addCase(fetchChangeUserDiscord.pending, (state) => {
        state.statusDiscord = EStatus.loading;
      })
      .addCase(fetchChangeUserDiscord.fulfilled, (state, action) => {
        state.statusDiscord = EStatus.success;

        if (state.user) {
          state.user.discord_name = action.payload;
        }
      })
      .addCase(fetchChangeUserDiscord.rejected, (state) => {
        state.statusDiscord = EStatus.rejected;
      })

      .addCase(fetchChangeUserTelegram.pending, (state) => {
        state.statusTelegram = EStatus.loading;
      })
      .addCase(fetchChangeUserTelegram.fulfilled, (state, action) => {
        state.statusTelegram = EStatus.success;

        if (state.user) {
          state.user.telegram_name = action.payload;
        }
      })
      .addCase(fetchChangeUserTelegram.rejected, (state) => {
        state.statusTelegram = EStatus.rejected;
      })

      .addCase(fetchChangeConnectedGA.pending, (state) => {
        state.statusGA = EStatus.loading;
      })
      .addCase(fetchChangeConnectedGA.fulfilled, (state, action) => {
        state.statusGA = EStatus.success;
        if (state.settings) {
          if (action.payload.type === 'disabled') {
            state.connectedGALink = null;
          }
          if (action.payload.type === 'enabled') {
            if ('secret_key' in action.payload.data && 'uri' in action.payload.data) {
              state.connectedGALink = action.payload.data;
            }
          }
        }
      })
      .addCase(fetchChangeConnectedGA.rejected, (state) => {
        state.statusGA = EStatus.rejected;
      })

      .addCase(fetchChangeSmsType.pending, (state) => {
        state.statusSmsType = EStatus.loading;
      })
      .addCase(fetchChangeSmsType.fulfilled, (state) => {
        state.statusSmsType = EStatus.success;
      })
      .addCase(fetchChangeSmsType.rejected, (state) => {
        state.statusSmsType = EStatus.rejected;
      })

      .addCase(fetchUserIpAddresses.pending, (state) => {
        state.IpAddresses = null;
      })
      .addCase(fetchUserIpAddresses.fulfilled, (state, action) => {
        state.IpAddresses = action.payload;
      })
      .addCase(fetchUserIpAddresses.rejected, (state) => {
        state.IpAddresses = null;
      })

      .addCase(fetchUserAddIpAddresses.pending, (state) => {
        state.statusAddIpAdress = EStatus.loading;
      })
      .addCase(fetchUserAddIpAddresses.fulfilled, (state, action) => {
        state.statusAddIpAdress = EStatus.success;

        if (state.IpAddresses) {
          state.IpAddresses.push(action.payload);
        } else {
          state.IpAddresses = [action.payload];
        }
      })
      .addCase(fetchUserAddIpAddresses.rejected, (state) => {
        state.statusAddIpAdress = EStatus.rejected;
      })

      .addCase(fetchUserDeleteIpAddresses.pending, (state) => {
        state.statusDeleteIpAdress = EStatus.loading;
      })
      .addCase(fetchUserDeleteIpAddresses.fulfilled, (state) => {
        state.statusDeleteIpAdress = EStatus.success;
      })
      .addCase(fetchUserDeleteIpAddresses.rejected, (state) => {
        state.statusDeleteIpAdress = EStatus.rejected;
      })

      .addCase(fetchUserKycStatus.fulfilled, (state, action) => {
        state.kycStatus = action.payload;
      })

      .addCase(fetchChangePassword.pending, (state) => {
        state.statusChangePassword = EStatus.loading;
      })
      .addCase(fetchChangePassword.fulfilled, (state) => {
        state.statusChangePassword = EStatus.success;
      })
      .addCase(fetchChangePassword.rejected, (state) => {
        state.statusChangePassword = EStatus.rejected;
      })
      .addCase(fetchUserRebatesSettings.pending, (state) => {
        state.rebates.settings = null;
      })
      .addCase(fetchUserRebatesSettings.fulfilled, (state, action) => {
        state.rebates.settings = action.payload;
      })
      .addCase(fetchUserRebatesSettings.rejected, (state) => {
        state.rebates.settings = null;
      });
  },
});

export const {
  addLoginVerificationType,
  removeLoginVerificationTypes,
  removeConnectedGALink,
  removeUserSlice,
  activeGA,
  setCurrentTogglerTwoFA,
  setTypeSmsCode,
  removeIpAddress,
  cleareIpAddress,
  setKycStatus,
  setKycVerify,
  setIpAddress,
  themeMode,
  disabledPhoneVerification,
} = userSlice.actions;

export const userReducer = userSlice.reducer;
