import { createSlice } from '@reduxjs/toolkit';
import { EStatus } from 'src/shared/types/global-types';
import { IExchangeSlice } from './types';
import { fetchExchanges } from './thunks';

const initialState: IExchangeSlice = {
  exchanges: null,
  status: EStatus.success,
};

export const exchangesSlice = createSlice({
  name: 'exchanges',
  initialState,
  reducers: {
    removeExchange: (state) => {
      state.exchanges = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchExchanges.pending, (state) => {
        state.status = EStatus.loading;
      })
      .addCase(fetchExchanges.fulfilled, (state, action) => {
        state.status = EStatus.success;
        state.exchanges = action.payload.slice().sort((a, b) => a.id - b.id);
      })
      .addCase(fetchExchanges.rejected, (state) => {
        state.status = EStatus.rejected;
        state.exchanges = null;
      });
  },
});

export const { removeExchange } = exchangesSlice.actions;

export const exchangesReducer = exchangesSlice.reducer;
