import {
  Language, LoginVerificationType, SendTypeCode,
} from '../types/global-types';

const { REACT_APP_WEB_SOCKET_PORT, REACT_APP_HOST_URL } = window.appConfig;

const START_PAGE = 'trading';
const HTTPS = 'https://';

const BASE_URL = HTTPS + REACT_APP_HOST_URL;
const WEB_SOCKET_URL = `wss://${REACT_APP_HOST_URL}/wss:${REACT_APP_WEB_SOCKET_PORT}/?token=`;

const TIMER_SEND_SMS = 90;
const COUNTER_SEND_SMS = 3;

const USDT_PRECISION = 1;

const LANGUEAGES: Language[] = [
  {
    type: 'ru',
    name: 'Русский',
  },
  // {
  //   type: 'en',
  //   name: 'English',
  // },
];

const HIDDEN_BALANCES = '*****';

const VERIFICATION_TYPES: LoginVerificationType[] = [
  {
    name: 'По телефону',
    type: 'phone',

  },
  {
    name: 'Через Google Authenticator',
    type: 'ga',
  },
  // {
  //   name: 'Через Yubikey',
  //   type: 'yubikey',
  // },
];

const SMS_TYPES: SendTypeCode[] = [
  {
    type: 'whatsapp',
    name: 'WhatsApp',
  },
  {
    type: 'phone',
    name: 'SMS',
  },
];

const SYSTEM_NOTIFICATION = 'В Личный кабинет были внесены изменения. Для корректной работы рекомендуется обновить страницу.';

const DEFAULT_LANGUEAGE: Language = {
  type: 'ru',
  name: 'Русский',
};

const DEFAULT_BALANCE = {
  sub_account_id: 1,
  spot: '0.0',
  spot_allowed: '0',
  spot_pnl_usdt: '0.0',
  spot_pnl_percent: '0.00%',
  futures: '0.0',
  futures_allowed: '0',
  futures_pnl_usdt: '0.0',
  futures_pnl_percent: '0.00%',
  total_pnl_usdt: '0.0',
  total_pnl_percent: '0.0',
};

const KEYBOARD_LAYOUT: { [key: string]: string } = {
  й: 'q',
  ц: 'w',
  у: 'e',
  к: 'r',
  е: 't',
  н: 'y',
  г: 'u',
  ш: 'i',
  щ: 'o',
  з: 'p',
  х: '[',
  ъ: ']',
  ф: 'a',
  ы: 's',
  в: 'd',
  а: 'f',
  п: 'g',
  р: 'h',
  о: 'j',
  л: 'k',
  д: 'l',
  ж: ';',
  э: "'",
  я: 'z',
  ч: 'x',
  с: 'c',
  м: 'v',
  и: 'b',
  т: 'n',
  ь: 'm',
  б: ',',
  ю: '.',
  ё: '|',
  '.': '/',
  Й: 'Q',
  Ё: '|',
  Ц: 'W',
  У: 'E',
  К: 'R',
  Е: 'T',
  Н: 'Y',
  Г: 'U',
  Ш: 'I',
  Щ: 'O',
  З: 'P',
  Х: '{',
  Ъ: '}',
  Ф: 'A',
  Ы: 'S',
  В: 'D',
  А: 'F',
  П: 'G',
  Р: 'H',
  О: 'J',
  Л: 'K',
  Д: 'L',
  Ж: ':',
  Э: '"',
  Я: 'Z',
  Ч: 'X',
  С: 'C',
  М: 'V',
  И: 'B',
  Т: 'N',
  Ь: 'M',
  Б: '<',
  Ю: '>',
  ',': '?',
};

const TIME_INTERVALS = [
  '00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00',
  '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00',
  '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00',
];

const PRICE_PRECISION_EXCHANGES = {
  bybit: 9,
  binance: 8,
};

export {
  BASE_URL,
  START_PAGE,
  TIMER_SEND_SMS,
  COUNTER_SEND_SMS,
  LANGUEAGES,
  DEFAULT_LANGUEAGE,
  WEB_SOCKET_URL,
  DEFAULT_BALANCE,
  VERIFICATION_TYPES,
  SMS_TYPES,
  KEYBOARD_LAYOUT,
  USDT_PRECISION,
  SYSTEM_NOTIFICATION,
  HIDDEN_BALANCES,
  TIME_INTERVALS,
  PRICE_PRECISION_EXCHANGES,
};
