export const NAVIGATION_LINKS = [
  {
    path: '/screener',
    title: 'Скринер',
  },
  {
    path: '/diary',
    title: 'Дневник',
  },
  {
    path: '/algo-trading',
    title: 'Алготрейдинг',
  },
  {
    path: '/alerts',
    title: 'Алерты',
  },
  {
    path: '/news',
    title: 'Новости',
  },
];
